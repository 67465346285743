<script>
  import { tweened } from "svelte/motion";
  import { cubicOut } from "svelte/easing";
  import { PageFlipStore } from "../stores/page-flip-store";

  export let links = [];
  export let darkMode = false;
  const navPositions = {
    closed: -950,
    open: -500,
    hide: -1050,
  };
  const navLeftPosition = window.innerWidth / 2 + window.innerWidth * 0.3;
  $: targetPosition = navPositions.hide;
  let currentPosition = tweened(navPositions.hide, { easing: cubicOut });
  $: currentPosition.set(targetPosition, { easing: cubicOut });
  $: addFlipEventListener($PageFlipStore);

  function getNextNavState(currentState) {
    if (currentState === navPositions.closed) return navPositions.open;
    if (currentState === navPositions.open) return navPositions.closed;
    if (currentState === navPositions.hide) return navPositions.closed;
  }
  function onNavigationClick() {
    targetPosition = getNextNavState(targetPosition);
  }
  function addFlipEventListener(element) {
    if (element) {
      element.on("flip", function onFlip(flipEvent) {
        const pageNumber = flipEvent.data;
        if (pageNumber >= 1) targetPosition = getNextNavState(targetPosition);
        if (pageNumber === 0) targetPosition = navPositions.hide;
      });
    }
  }
  function onLinkClick(event) {
    event.stopPropagation();
  }
  function onToggleDarkMode(event) {
    event.stopPropagation();
    darkMode = !darkMode;
  }
</script>

<nav
  style="transform: translate({navLeftPosition}px,{$currentPosition}px)"
  on:click={onNavigationClick}
>
  <ul id="navlinks">
    {#each links as link}
      {#if link.show}
        <li on:click={(e) => onLinkClick(e)}>
          <a href={link.route}> {link.name} </a>
        </li>
      {/if}
    {/each}
  </ul>
  <ul id="light-mode" on:click={onToggleDarkMode}>
    <li id="dark" class={darkMode ? "hide" : ""}>
      <span class="material-icons"> nightlight </span>
    </li>
    <li id="light" class={darkMode ? "" : "hide"}>
      <span class="material-icons"> wb_sunny </span>
    </li>
  </ul>
</nav>

<style>
  nav {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    z-index: 100;
    width: 200px;
    height: 1040px;
    top: 0;
    left: 0;
    color: gold;
    background-image: url("../assets/images/Ribbon.png");
    background-size: 100% 100%;
  }
  nav ul {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    font-size: 1.5rem;
  }
  nav a {
    display: block;
    width: 100%;
    padding: 0.5rem;
    color: gold;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
  }
  nav ul:last-child {
    margin-bottom: 150px;
  }
  nav ul li:hover {
    color: goldenrod;
  }
  .hide {
    display: none;
  }
</style>
