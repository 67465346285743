import { writable } from "svelte/store";

function createCurrentUserStore() {
  const sessionUser = sessionStorage.getItem("currentUser");
  const { subscribe, set } = writable(sessionUser);
  return {
    subscribe,
    save: (value) => {
      sessionStorage.setItem("currentUser", JSON.stringify(value));
      set(value);
    },
  };
}

export const CurrentUserStore = createCurrentUserStore();

//Format:
// {
// 	uid,
// 	display_name,
// 	email,
// 	phone_number,
// 	photo_url,
// 	provider_id,
// }
