import { writable } from "svelte/store";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
function CreatePreviewFileStore() {
  const storage = getStorage();
  const { subscribe, set } = writable(null);
  return {
    subscribe,
    setFile: (file) => {
      getDownloadURL(ref(storage, file.fullPath)).then((url) => {
        const newFile = { ...file, url };
        set(newFile);
      });
    },
  };
}

export const PreviewFileStore = CreatePreviewFileStore();
