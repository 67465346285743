<script>
  export let name;
  export let id = name;
  export let value;

  let textInput;
  function onInputClick() {
    textInput.focus();
  }
</script>

<input
  type="text"
  {id}
  {name}
  bind:value
  on:click={onInputClick}
  bind:this={textInput}
/>

<style>
  input {
    width: 100%;
    height: 2rem;
    margin-bottom: 2rem;
    color: black;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid black;
  }
  input:focus {
    border: none;
    border-bottom: 1px solid black;
    outline: none;
  }
</style>
