<script>
</script>

<main>
  <h2>Geplande evenementen</h2>
  <p />
  <p>
    Chalice 20: Calamity III<br />
    vrijdag 11 november 2022 - 12 november 2022<br />
    Woutershof - Kinrooi, België<br />
    <a href="https://www.facebook.com/events/1841388426019510" target="_blank">
      facebook
    </a>
  </p>
</main>

<style>
  p {
    font-family: 'Times New Roman', Times, serif
  }
</style>
