<script>
  import { UsersStore } from "../stores/users-store.js";
  import { CharactersStore } from "../stores/characters-store.js";
  import { CharacterFoldersStore } from "../stores/characters-file-store.js";
  import { PreviewFileStore } from "../stores/preview-file-store.js";
  import { RegistrationFormStore } from "../stores/registration-form-store.js";
  import CollapseItem from "./collapse-item.svelte";
  $: users = $UsersStore;
  $: characters = $CharactersStore;
  $: folders = $CharacterFoldersStore;
  $: charactersWithFolder = mapFolderToCharacters(characters, folders);
  $: userWithCharacters = mapCharacterToUsers(users, charactersWithFolder);

  function mapFolderToCharacters(characters, folders) {
    if (characters && characters.length > 0 && folders && folders.length > 0) {
      return characters.map((character) => ({
        ...character,
        files:
          folders.find((folder) => character.folderId === folder.name).items ||
          [],
      }));
    } else return [];
  }

  function mapCharacterToUsers(users, characters) {
    if (users && users.length > 0 && characters && characters.length > 0) {
      return users.map((user) => ({
        ...user,
        characters: user.characters?.map((id) => characters[id]) || [],
      }));
    } else return [];
  }

  function selectFile(file) {
    PreviewFileStore.setFile(file);
  }

  function selectForm() {
    RegistrationFormStore.set({
      url: "https://docs.google.com/forms/d/e/1FAIpQLSeW-XLd4iMDsOipJgcA8RQhQKi9hHjvRez1bEq0j51Z_643sw/viewform?embedded=true",
    });
  }
</script>

<main>
  <aside>
    <h2>file viewer</h2>
    <p on:click={selectForm}>Inschrijven Chalice 20: Calamity III</p>
    {#each userWithCharacters as user}
      <CollapseItem title={user.name}>
        {#each user.characters as character}
          {#if character}
            <CollapseItem title={character.name}>
              {#each character.files as file}
                <p on:click={() => selectFile(file)}>{file.name}</p>
              {/each}
            </CollapseItem>
          {/if}
        {/each}
      </CollapseItem>
    {/each}
  </aside>
</main>

<style>
  main {
    display: flex;
    flex-direction: row;
    height: 100%;
  }
  aside {
    width: 100%;
    flex-grow: 1;
    padding-left: 1rem;
    outline: 1px solid black;
  }

  p {
    padding: 1rem;
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
    text-decoration: underline;
  }
  p:active {
    background-color: rgba(0, 0, 0, 0.2);
  }
  p:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
</style>
