<script>
  let src = "/assets/images/cover/Logo.png";
</script>

<main class="cover page">
  <div class="corner top-left" />
  <div class="corner top-right" />
  <div class="corner bottom-right" />
  <div class="corner bottom-left" />
  <img {src} alt="golden chalice logo" class="logo" />
</main>

<style>
  :root {
    --corner-side: 200px;
  }
  .cover {
    position: relative;
    background-image: url("../assets/images/cover/cover_blank.png");
  }
  .corner {
    position: absolute;
    width: var(--corner-side);
    height: var(--corner-side);
    background-size: var(--corner-side) var(--corner-side);
  }
  .top-left {
    top: 0;
    left: 0;
    background-image: url("../assets/images/cover/CornerTopLeft.png");
  }
  .top-right {
    top: 0;
    right: 0;
    background-image: url("../assets/images/cover/CornerTopRight.png");
  }
  .bottom-right {
    bottom: 0;
    right: 0;
    background-image: url("../assets/images/cover/CornerBottomRight.png");
  }
  .bottom-left {
    bottom: 0;
    left: 0;
    background-image: url("../assets/images/cover/CornerBottomLeft.png");
  }
  .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 450px;
    height: 450px;
    transform: translate(-50%, -50%);
  }
  /* TODO add with and height percent to center logo */
</style>
