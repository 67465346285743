<script>
  let srcs = ["./assets/images/photos/infoFoto.png"];
</script>

<main>
  <img src={srcs[0]} alt="dungeon wall" />
</main>

<style>
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
</style>
