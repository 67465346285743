<script>
  export let name;
  export let id = name;
  export let value;
  let textArea;
  function onTextAreaClick() {
    textArea.focus();
  }
</script>

<textarea
  {name}
  {id}
  bind:this={textArea}
  bind:value
  on:click={onTextAreaClick}
/>

<style>
  textarea {
    width: calc(100%-2em);
    height: 10rem;
    padding: 2em;
    background-color: transparent;
    border: 1px solid black;
  }
</style>
