import { readable } from "svelte/store";
import { PageFlip } from "page-flip";
export const PageFlipStore = readable(undefined, (set) => {
  const update = () => {
    const bookWidth = window.innerWidth / 2 - convertRemToPixels(1);
    const bookHeight = window.innerHeight - convertRemToPixels(1) * 2;
    const pageElements = document.querySelectorAll(".page");
    const book = document.getElementById("book");
    let pageFlip = new PageFlip(book, {
      width: bookWidth,
      height: bookHeight,
      showCover: true,
      flippingTime: 500,
    });
    pageFlip.loadFromHTML(pageElements);
    pageFlip.on("flip", (e) => {
      if (e.data >= 1) {
        window.removeEventListener("mouseup", pageFlip.getUI().onMouseUp);
        window.removeEventListener("mousemove", pageFlip.getUI().onMouseMove);
        window.removeEventListener("touch", pageFlip.getUI().onMouseUp);
      }
      if (e.data === 0) {
        window.addEventListener("mouseup", pageFlip.getUI().onMouseUp);
        window.addEventListener("mousemove", pageFlip.getUI().onMouseMove);
        window.addEventListener("touch", pageFlip.getUI().onMouseMove);
      }
    });
    set(pageFlip);
  };
  document.addEventListener("DOMContentLoaded", update);
  return () => document.removeEventListener("DOMContentLoaded", update);
});

function convertRemToPixels(rem) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}
