<script>
</script>

<main>
  <h2>Regels</h2>
  <p>
    Bij het maken en aanpassen van het spelsysteem wordt één gouden regel altijd
    voor ogen gehouden: bespeelbaarheid moet zo natuurlijk mogelijk zijn. Zo
    zijn er altijd praktische limitaties. Een vaardigheid waarmee je kan vliegen
    lijkt leuk, maar is niet speelbaar. Volledige controle over iemands acties
    hebben, zou een krachtige spreuk zijn, maar dit kan het spelplezier van het
    slachtoffer wegnemen.
  </p>
  <p>
    We streven dan ook naar voor de hand liggende spelregels met een optimaal
    resultaat in de beleving. We rekenen dan ook op fair-play bij het uitspelen
    en rekening houden van effecten en opgelopen wonden. Hierdoor is het niet
    nodig om een gevecht te pauzeren en schade te noteren of om een figurant te
    zoeken om een effect te noteren.
  </p>
</main>

<style>
</style>
