<script>
  let srcs = ["/assets/images/letters/letter-D.png"];
</script>

<main>
  <h1>Foto's</h1>
  <p>
    <img
      class="letter-img"
      src={srcs[0]}
      alt="Medival illustration of the letter D"
    />
    oor je in te schrijven voor een evenement ga je automatisch akkoord dat er foto’s
    van je kunnen genomen worden en dat je mogelijks herkenbaar zal zijn. Deze fotos
    worden beschikbaar gemaakt voor de leden van onze facebook pagina. Geen nood
    als je geen facebook bezit. We kunnen de fotos ook op aanvraag (tijdelijk) beschikbaar
    maken via een ander kanaal.
  </p>
  <h2>Gezocht</h2>
  <p>
    Ben jij, of ken jij een (hobby) fotograaf die een evenement mee in beeld wil
    brengen? Aarzel dan niet om contact op te nemen. Door de vele gebeurtenissen
    op het evenement, durft onze crew wel al eens te vergeten om stil te staan
    en een ‘kiekje’ te nemen. De figuranten en speler groep zal je eeuwig
    dankbaar zijn voor de blijvende herinnering.
  </p>
</main>

<style>
</style>
