import { writable } from "svelte/store";
import { getStorage, ref, listAll } from "firebase/storage";

const CharacterFoldersStore = writable([]);
const storage = getStorage();
const characterStoreRef = ref(storage, "Characters");

listAll(characterStoreRef).then((res) => {
  const FileStore = res.prefixes.map((prefixe) => {
    const folder = {
      name: prefixe.name,
    };
    listAll(prefixe).then((subPrefixe) => {
      folder.items = subPrefixe.items;
    });
    return folder;
  });
  CharacterFoldersStore.set(FileStore);
});

export { CharacterFoldersStore };
