import { writable } from "svelte/store";
import { getDatabase, ref, onValue } from "firebase/database";
const usersPath = "users/";
const db = getDatabase();
const userRef = ref(db, usersPath);
const UsersStore = writable([]);

onValue(userRef, (snapshot) => {
  let users = [];
  snapshot.forEach((childSnapshot) => {
    users.push(childSnapshot.val());
  });
  UsersStore.set(users);
});

export { UsersStore };
