<script>
  import TextInput from "../components/text-input.svelte";
  import PassInput from "../components/pass-input.svelte";
  import Button from "../components/button.svelte";
  import { RegistrationFormStore } from "../stores/registration-form-store.js";
  import { signIn } from "../firebase/firebase";
  import { CurrentUserStore } from "../stores/current-user-store";

  let credentials = {};
  let allErrors = [];

  function emailValidation(email) {
    let emailErrors = [];
    if (!email || email === "") {
      emailErrors.push({
        field: "email",
        msg: "Email mag niet leeg zijn.",
      });
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      emailErrors.push({
        field: "email",
        msg: "Email adress is niet heldig.",
      });
    }
    return emailErrors;
  }
  function passValidation(pass) {
    let passErrors = [];
    if (!pass || pass === "") {
      passErrors.push({
        field: "pass",
        msg: "Wachtwoord mag niet leeg zijn.",
      });
    }
    return passErrors;
  }
  function validateLogin(credentials) {
    return [
      ...emailValidation(credentials.email),
      ...passValidation(credentials.pass),
    ];
  }

  function login(event) {
    allErrors = validateLogin(credentials);
    if (!allErrors || !allErrors.length === 0) return;
    event.preventDefault();
    event.stopPropagation();
    signIn(credentials)
      .then((cred) => {
        CurrentUserStore.save(cred.user);
      })
      .catch((err) => {
        allErrors.push("Email of Wachtwoord zijn niet correct.");
      });
  }

  function selectForm() {
    RegistrationFormStore.set({
      url: "https://docs.google.com/forms/d/e/1FAIpQLSeW-XLd4iMDsOipJgcA8RQhQKi9hHjvRez1bEq0j51Z_643sw/viewform?embedded=true",
    });
  }
</script>

<main>
  <div class="form">
    <label for="email">Email</label>
    <TextInput name={"email"} bind:value={credentials.email} />
    <label for="password">Wachtwoord</label>
    <PassInput name={"password"} bind:value={credentials.pass} />
    <div class="row">
      <Button on:buttonClick={(event) => login(event)}>Login</Button>
    </div>
  </div>
  <p on:click={selectForm}>Inschrijven Chalice 20: Calamity III</p>
  {#if allErrors.length > 0}
    <ul>
      {#each allErrors as error}
        <li>{error.msg}</li>
      {/each}
    </ul>
  {/if}
</main>

<style>
  ul {
    padding: 2em;
    border: 1px solid darkred;
    color: darkred;
  }
  ul li {
    list-style: circle;
  }
  .row {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: end;
  }

  p {
    padding: 1rem;
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
    text-decoration: underline;
  }
  p:active {
    background-color: rgba(0, 0, 0, 0.2);
  }
  p:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
</style>
