<script>
</script>

<main>
  <p>
    Chalice 20 Is het 3de deel van de slot trilogie van de huidige verhaallijn
    van Chalice. De huidige gebeurtenissen zullen dan ook grote invloed hebben
    op onze wereld.
  </p>
  <p>Het verhaal gaat verder na de gebeurtenissen van Chalice 19.</p>
  <p>
    Wegens Corona hebben we beslist om Chalice 19 op papier door te laten gaan
    en rechtstreeks naar Chalice 20 te springen.
  </p>
  <p>
    Hierdoor zullen alle personages (zowel de nieuwe als de bestaande) 5 extra
    XP krijgen om hun personage aan te passen.
  </p>
  <p>
    Iedereen die zich inschrijft voor deze live zal een document ontvangen met
    de gebeurtenissen van Chalice 19.
  </p>
  <p>
    We hopen zoveel mogelijk figuranten van de vorige keer terug te verwelkomen
    om hun rol verder te zetten maar sluiten niet uit dat bepaalde rollen van
    uiterlijk zullen veranderen.
  </p>
</main>

<style>
  p {
    font-family: 'Times New Roman', Times, serif;
  }
</style>
