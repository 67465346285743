<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = new createEventDispatcher();

  function onButtonClick(event) {
    dispatch("buttonClick", event);
  }
</script>

<button on:click={(e) => onButtonClick(e)}>
  <slot />
</button>

<style>
  button {
    padding: 2em;
    background-color: transparent;
    background-image: url("../assets/images/button/buttonborder.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border: none;
    box-shadow: silver;
  }
  button:active {
    box-shadow: inset 0 0 1em 0 rgba(0, 0, 0, 0.5);
  }
</style>
