<script>
  import Button from "../components/button.svelte";
  import TextInput from "../components/text-input.svelte";
  import TextArea from "../components/text-area.svelte";
  import { sendContactInfo } from "../firebase/functions";

  let allErrors = [];
  let contactForm = {
    name: "",
    email: "",
    content: "",
  };
  let formMessage = "";

  //TODO refactor this to
  function emailValidation(email) {
    let emailErrors = [];
    if (!email || email === "") {
      emailErrors.push({
        field: "email",
        msg: "Email mag niet leeg zijn.",
      });
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      emailErrors.push({
        field: "email",
        msg: "Email adress is niet heldig.",
      });
    }
    return emailErrors;
  }
  function nameValidation(name) {
    let nameErrors = [];
    if (!name || name === "") {
      nameErrors.push({
        field: "name",
        msg: "Naam mag niet leeg zijn.",
      });
    }
    return nameErrors;
  }
  function contentValidation(content) {
    let contentErrors = [];
    if (!content || content === "") {
      contentErrors.push({
        field: "Content",
        msg: " Gelieve iets te schrijven in content.",
      });
    }
    return contentErrors;
  }
  function validateContactForm(form) {
    return [
      ...emailValidation(form.email),
      ...nameValidation(form.name),
      ...contentValidation(form.content),
    ];
  }

  function sendForm(event) {
    event.preventDefault();
    event.stopPropagation();
    allErrors = validateContactForm(contactForm);
    if (allErrors.length === 0) {
      sendContactInfo(JSON.stringify(contactForm))
        .then((response) => {
          console.log({ postResult: response });
          contactForm = {
            name: "",
            email: "",
            content: "",
          };
          formMessage =
            "Uw bericht is goed aan gekomen. Je kan binnen kort antwoord ontvangen.";
        })
        .catch((error) => {
          console.error(error);
          formMessage = "Er ging iets mis bij het versturen van uw bericht";
        });
    }
  }
  //TODO: check if i can add masks to button
  //https://css-tricks.com/clipping-masking-css/
</script>

<main>
  <h1>Contact</h1>
  <p>
    Als je nog vragen of ideeen hebt, kan je ons altijd vinden op facebook of
    via het formulier hieronder.<br />
    Facebook:
    <a href="https://www.facebook.com/groups/126766175183"> Chalice larp </a>
  </p>
  <div class="form">
    <label for="name">Naam</label>
    <TextInput name="name" bind:value={contactForm.name} />
    <label for="email" id="email-label">Uw email</label>
    <TextInput name="email" bind:value={contactForm.email} />
    <label for="content">Content</label>
    <TextArea name="content" bind:value={contactForm.content} />
    <div class="row">
      <Button on:buttonClick={(e) => sendForm(e)}>Verstuur</Button>
    </div>
  </div>
  {#if allErrors.length > 0}
    <ul>
      {#each allErrors as error}
        <li>{error.msg}</li>
      {/each}
    </ul>
  {/if}
  {#if formMessage !== ""}
    <p>
      {formMessage}
    </p>
  {/if}
</main>

<style>
  p {
    margin-top: 1rem;
  }
  ul {
    padding: 2em;
    border: 1px solid darkred;
    color: darkred;
  }
  ul li {
    list-style: circle;
  }
  .row {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: end;
  }
</style>
