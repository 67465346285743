<script>
  let srcs = ["/assets/images/letters/letter-C.png"];
</script>

<main>
  <img
    class="letter-img"
    src={srcs[0]}
    alt="Medival illustration of the letter C"
  />
  <h1>halice</h1>
  <p>
    Chalice is een LARP vereniging uit West-Vlaanderen (België) dat evenementen
    organiseert in een middeleeuws-fantasy wereld. De evenementen worden 1 tot 2
    maal per jaar georganiseerd en starten op vrijdagavond tot zaterdag
    middernacht met mogelijkheid tot overnachting tot zondagmorgen. We streven
    naar een goede balans tussen immersie en simpelweg plezier maken. Dit zowel
    voor de spelers als voor de figuranten die, laat ons eerlijk zijn,
    noodzakelijk zijn om de wereld voor één weekend tot leven te wekken. We
    hebben een zero-drug policy en verwachten dat alle aanwezigen elkaars
    grenzen en waarden respecteert.
  </p>
  <p>
    De wereld speelt zich af in een ‘high fantasy’ setting geïnspireerd door
    Dungeons & Dragons, Pathfinder, Warhammer, Lord of the rings … De focus bij
    ieder evenement ligt bovenal op beleving en het groeien van uw personage
    waarbij deze een letterlijk bijdraagt tot de wereld. Het scenario wordt dan
    ook aangepast door de acties en keuzes tijdens het evenement.
  </p>

  <h2>Larp</h2>
  <p>
    Live action role play kan je zien als een D&D session uitspelen in het echt.
  </p>

  <h2>Spelers</h2>
  <p>
    Als speler bouw je aan de hand van het spelsysteem een personage met een
    eigen achtergrond, vaardigheden, waardes, motivaties, zwaktes, … Dit zal je
    helpen bepalen hoe je reageert tijdens het evenement. Het voornaamste doel
    van een speler is het beleven en het ontdekken wat er rondom zich gebeurd en
    daarbij zijn eigen inbreng leveren. Zo kan je als speler kiezen om iemands
    leven te sparen of net iemand te op te offeren. Omdat je je eigen personage
    speelt, wordt er verwacht dat je deze ook met eigen materiaal aankleed.
  </p>

  <h2>Figuranten</h2>
  <p>
    Als figurant neem je één of meerdere rollen op zich die de wereld tot leven
    wekt. Dit varieert van de zombie op het kerkhof tot de heerser van het rijk.
    Figuranten spelen een cruciale rol in het evenement. Ze zullen een personage
    in het verhaal vertolken en inspelen op wat de spelers doen, binnen het
    concept van hun personage. Figuranten krijgen hiervoor een omschrijving van
    de rol met onder andere een achtergrond, doel in het leven, sterktes,
    zwaktes, … En kunnen altijd ideeën delen met de spelleiding om het personage
    nog verder uit te diepen. Niet alle te vertolken rollen hebben nood aan een
    voorbereiding. Een korte uitleg en wat verbeelding hoe iets zal reageren of
    vechten, kan genoeg zijn. Omdat je een voorgeschreven rol speelt, wordt het
    materiaal voor het personage door de spelleiding aangeboden. Als figurant
    zorg je voor om neutrale, donkere kledij en stevig schoeisel.
  </p>
</main>

<style>
</style>
