<script>
  export let darkMode = false;
  export let bgImgLeft = null;
  export let bgImgRight = null;
</script>

<main class="page" class:dark={darkMode}>
  <div class="container">
    {#if bgImgLeft}
      <img src={bgImgLeft} alt="Medival border" />
    {/if}
    <div class="content" class:extra-border={bgImgLeft || bgImgRight}>
      <slot />
    </div>
    {#if bgImgRight}
      <img class="right-img" src={bgImgRight} alt="Medival border" />
    {/if}
  </div>
</main>

<style>
  .page {
    background-color: bisque;
    color: black;
    border: 1px solid darkgray;
  }
  .page :global(a) {
    color: maroon;
  }
  .page.dark {
    background-color: hsl(184, 5%, 27%);
    color: white;
  }
  .page.dark :global(a) {
    color: hsl(240, 70%, 72%);
  }
  .container img {
    width: 7rem;
    height: calc(100% + 2rem * 2);
    margin-top: -2rem;
  }
  .right-img {
    transform: scaleX(-1);
  }
  .container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: calc(100% - 2rem * 2);
    height: calc(100% - 5rem * 2);
    padding-left: 2rem;
    padding-left: 2rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .content {
    width: 100%;
    height: 100%;
    margin-left: 2rem;
    margin-right: 1rem;
    padding-right: 1rem;
    overflow-y: auto;
    font-family: Tnanti, BrownBagLunch, DancingScript, Arial, Helvetica,
      sans-serif;
    font-weight: lighter;
    font-size: 1.3em;
    line-height: 1.8rem;
  }

  .content :global(h1) {
    display: inline-block;
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
    font-family: Natyl, Tangerine;
    font-size: 5rem;
  }
  .content :global(h2) {
    font-family: Natyl;
    font-size: 3rem;
    margin-top: 2.5rem;
    margin-left: 0.5rem;
    margin-bottom: 2rem;
  }
  .content :global(.letter-img) {
    display: inline;
    margin-right: -1.5rem;
    max-height: 10rem;
  }
  .content :global(p) {
    z-index: 100;
    margin-bottom: 1rem;
    text-align: justify;
    /* font-family: Tangerine, Arial, Helvetica, sans-serif; */
  }
  .content :global(p .letter-img) {
    display: block;
    float: left;
    margin-top: 0;
    margin-right: 0.5rem;
    max-height: 5rem;
  }
  .content :global(.form) {
    display: flex;
    flex-direction: column;
    width: 75%;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }
  .content :global(.form label) {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    font-family: Tangerine;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
  }
</style>
