<script>
  import { CurrentUserStore } from "../stores/current-user-store";
  import Login from "./login.svelte";
  import FileView from "../components/file-viever.svelte";
</script>

<main>
  {#if !$CurrentUserStore}
    <Login />
  {:else}
    <FileView />
  {/if}
</main>

<style>
  main {
    height: 100%;
    overflow: hidden;
  }
</style>
