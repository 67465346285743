<script>
  let srcs = ["/assets/images/letters/letter-O.png"];
</script>

<main>
  <h1>Lore</h1>
  <p>
    <img
      class="letter-img"
      src={srcs[0]}
      alt="Medival illustration of the letter O"
    />
    m het spel optimaal te beleven, stimuleren we iedere deelnemer een achtergrond
    te bedenken. Samen kunnen we deze in de wereld integreren. Er is een volledige
    wereld ter beschikking om je te laten inspireren en mocht dit toch nog niet voldoende
    zijn, kunnen we nog altijd samen kijken wat mogelijk is om je idee te leven te
    wekken. Op deze manier zijn er al verschillende volledige rijken en rassen ontstaan
    die nu een vaste waarde hebben in de wereld van Chalice.
  </p>
  <p>
    Niet enkel spelers worden aangemoedigt om een achtergrond te maken, ook
    figuranten kunnen hun inbreng geven in de rol die ze zullen
    verpersoonlijken. De beste manier om een indruk achter te laten is door één
    te zijn met het personage. Het meest bekende personage die een volledige
    groei doorliep is Morkar. Hij ontstond als een nevenfiguur in een plotlijn
    en eindigde als een demi-god. Sommigen beweren zelfs dat hij een god is.
  </p>
  <p>
    Met andere woorden, jouw achtergrond en je acties tijdens evenementen worden
    opgepikt en verwerkt tijdens het schrijven van het scenario en zal vroeg of
    laat je pad kruisen. Zo werd een simpele krijger die het gevecht in stormde
    en dan pas vragen stelde, tegen wil en dank, de redding van de godenwereld.
  </p>
  <div>
    <p style="font-style:italic">
      “Ærthe is niet meer wat het was na de terugkomst van Memnon. Het is
      grimmiger, valser, gevaarlijker alsof de goden ons verlaten hebben.”
      <br /> - Adriaan De Kouwer, Priester van Skye
    </p>
  </div>
</main>

<style>
  div {
    padding: 1.5rem;
    border: 1px solid gray;
    max-width: 500px;
  }
  div p {
    margin: 0;
    text-align: right;
  }
</style>
