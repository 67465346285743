<script>
  import Nav from "./components/nav.svelte";
  import Cover from "./pages/cover.svelte";
  import Page from "./components/page.svelte";
  import Router from "./components/router.svelte";
  import {
    ContactLeft,
    ContactRight,
    EventLeft,
    EventRight,
    InfoLeft,
    InfoRight,
    LoreLeft,
    LoreRight,
    PhotosLeft,
    PhotosRight,
    RegistrationLeft,
    RegistrationRight,
    RulesLeft,
    RulesRight,
  } from "./pages/page-modules";

  let darkMode = false;
  let links = [
    { name: "Info", route: "#/info", show: true },
    { name: "Foto's", route: "#/photo", show: true },
    { name: "Events", route: "#/events", show: true },
    { name: "Inschrijven", route: "#/submision", show: true },
    { name: "Lore", route: "#/lore", show: true },
    { name: "Regels", route: "#/rules", show: true },
    { name: "Contact", route: "#/contact", show: true },
  ];
</script>

<main class="background">
  <Nav {links} bind:darkMode />
  <Router {links} />
  <div id="book">
    <Cover />
    <Page {darkMode}><InfoLeft /></Page>
    <Page {darkMode}><InfoRight /></Page>
    <Page {darkMode}><PhotosLeft /></Page>
    <Page {darkMode}><PhotosRight /></Page>
    <Page {darkMode}><EventLeft /></Page>
    <Page {darkMode} bgImgLeft={"./assets/images/borders/border1v02.png"}
      ><EventRight /></Page
    >
    <Page {darkMode}><RegistrationLeft /></Page>
    <Page {darkMode}><RegistrationRight /></Page>
    <Page {darkMode}><LoreLeft /></Page>
    <Page {darkMode} bgImgRight={"./assets/images/borders/border2v02.png"}
      ><LoreRight /></Page
    >
    <Page {darkMode}><RulesLeft /></Page>
    <Page {darkMode}><RulesRight /></Page>
    <Page {darkMode}><ContactLeft /></Page>
    <Page {darkMode}><ContactRight /></Page>
  </div>
</main>

<style>
  .background {
    background-image: url("../assets/images/desk-background.jpg");
    width: 100%;
    height: 100%;
    position: absolute;
    filter: blur(0);
    overflow: hidden;
  }
  #book {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
</style>
