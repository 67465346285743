<script>
  import { PreviewFileStore } from "../stores/preview-file-store.js";
  import { RegistrationFormStore } from "../stores/registration-form-store.js";
  import { onDestroy } from "svelte";
  let file = null;
  let form = null;
  const subscriptions = [
    PreviewFileStore.subscribe((previewFile) => {
      file = previewFile;
      form = null;
    }),
    RegistrationFormStore.subscribe((registrationFrom) => {
      form = registrationFrom;
      file = null;
    }),
  ];

  onDestroy(() => {
    subscriptions.forEach(unSubscribe);
  });
</script>

<main>
  {#if file}
    <iframe
      title={file.name}
      src={file.url}
      frameborder="0"
      marginheight="0"
      marginwidth="0"
    />
  {/if}
  {#if form}
    <iframe
      src={form.url}
      title="inschrijving"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
    >
      Loading…
    </iframe>
  {/if}
</main>

<style>
  main {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 0;
    margin: 0;
  }
  iframe {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    outline: 1px solid black;
    overflow: hidden;
  }
</style>
