<script>
  export let title;
  export let collapse = false;
  function collapseChild() {
    collapse = !collapse;
  }
</script>

<main>
  <div class="title" on:click={collapseChild}>
    <h3>{title}</h3>
  </div>
  {#if !collapse}
    <div class="child">
      <slot />
    </div>
  {/if}
</main>

<style>
  .title {
    padding: 1rem 0 1rem 1rem;
    font-weight: 200;
    border-bottom: 1px solid black;
  }
  .title h3 {
    font-size: 1.8rem;
  }
  .title:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .title:active {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .child {
    width: 100%;
    margin-left: 1rem;
    border-left: 1px solid black;
  }
</style>
