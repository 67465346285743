import * as firebase from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  signInWithEmailAndPassword,
  connectAuthEmulator,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCVtdtsRtzzLyb8xHxlswQyxOeqdFoxbc4",
  authDomain: "chalicelarp-bee3a.firebaseapp.com",
  databaseURL: "https://chalicelarp-bee3a.firebaseio.com",
  projectId: "chalicelarp-bee3a",
  storageBucket: "chalicelarp-bee3a.appspot.com",
  messagingSenderId: "222937896979",
  appId: "1:222937896979:web:b0cd66209ea2c66f8b07e6",
  measurementId: "G-RH09SXQZVF",
};
const app = firebase.initializeApp(firebaseConfig);
const functions = getFunctions(app);
const analytics = getAnalytics(app);
const database = getDatabase(app);
const auth = getAuth();
self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LdDVJYdAAAAACnGOUBWEqt22RQKDSEwXYprASck"), //Live
  isTokenAutoRefreshEnabled: true,
});

function signIn({ email, pass }) {
  return signInWithEmailAndPassword(auth, email, pass);
}

// Run on localhost:
// connectAuthEmulator(auth, "http://localhost:9099");
// connectDatabaseEmulator(database, "localhost", 9000);
// connectFunctionsEmulator(functions, "localhost", 5001);

export { appCheck, database, functions, signIn };
//"cbPvFLH4uqZWNqODpO6k3fBnqwV2"
