<script>
  //TODO replace links with links to chalice drive
  //TODO na vragen tot welk niveau de spreuken zicht baar moeten zijn.
</script>

<main>
  <p>Hieronder vind je een link naar ons regel boek.</p>
  <a
    href="https://drive.google.com/file/d/1ChFK5JN4AcwEUH3H--NEx02kS_DY6u1p/view?usp=sharing"
    target="_blank">Regel Boek</a
  >
  <p>
    Indien je bepaalde spreuken nodig heb, gebruik dan 1 van de volgende links.
  </p>
  <a
    href="https://drive.google.com/file/d/1grl0EP8LmpGEyYmK1M9WEtMVQiYj-DB6/view?usp=sharing"
    target="_blank"
  >
    Wereldlijke Magie
  </a>
  <a
    href="https://drive.google.com/file/d/1LCcBGIYZ97wHXvGATx8g6PbsvFtY70mv/view?usp=sharing"
    target="_blank"
  >
    Godelijke Magie
  </a>
</main>

<style>
  a {
    display: inline-block;
    width: 100%;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
</style>
