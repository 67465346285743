import { writable } from "svelte/store";
import { getDatabase, ref, onValue } from "firebase/database";
const charactersPath = "characters/";
const db = getDatabase();
const characterRef = ref(db, charactersPath);
const CharactersStore = writable([]);

onValue(characterRef, (snapshot) => {
  let characters = [];
  snapshot.forEach((childSnapshot) => {
    characters.push(childSnapshot.val());
  });
  CharactersStore.set(characters);
});
export { CharactersStore };
