<script>
  import { RouterStore } from "../stores/routing-store";
  import { PageFlipStore } from "../stores/page-flip-store";
  export let links = [];

  $: followRoute($RouterStore, $PageFlipStore);

  function followRoute(route, pageFlip) {
    if (!route || !pageFlip) return;
    const index = links.findIndex((link) => link.route === route);
    const pageIndex = index < 0 ? 0 : index * 2 + 1;
    pageFlip.flip(pageIndex);
  }
</script>
